import { Container } from "react-bootstrap";
// import { FaClipboard, FaClipboardCheck } from "react-icons/fa";
// import useFetch from "../components/useFetch";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

const Enrollment = () => {
  const client = new ApolloClient({
    uri: "https://www.compact.family/wpapi/graphql/",
    cache: new InMemoryCache(),
  });

  const GET_PROJECTS = gql`
    query Projects {
      page(id: "enrollment", idType: URI) {
        databaseId
        title
        content
      }
    }
  `;

  const Projects = () => {
    document.title = "COMPACT Enrollment";

    const { loading, error, data: projects } = useQuery(GET_PROJECTS);

    if (loading) return "Loading...";
    if (error) return `Error! ${error.message}`;

    return (
      <>
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: projects.page.content }}
        ></div>
      </>
    );
  };

  // pulling from json
  // const {
  //   data: projects,
  //   isLoading,
  //   error,
  // } = useFetch("http://localhost:8001/projects");

  return (
    <>
      {/* <Container fluid className="photoblue">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex justify-content-center align-items-center">
              <div>
                <h1 className="display-5 text-center">Enrollment</h1>

                <p className="lead text-left">
                When you give to a project, you are giving to a specific need. It may
          be a project on our campus such as a new roof or renovating a cottage
          or it could be an emergent need such as diapers for our babies or new
          furniture for a cottage.
                </p>
              </div>
            </div>

            <div className="col-sm-12 col-md-7 d-flex align-content-end justify-content-center">
              <Image
                fluid
                src="https://compact.family/images/0622.jpg"
                className="img-fluid"
                alt="Child representative"
                layout="intrinsic"
                width="600"
                height="554"
              />
            </div>
          </div>
        </div>
      </Container> */}



      <Container fluid className="bg-blue text-white p-5 d-flex flex-column">
<p className="h4 text-center">Enrollment</p>

{/* <Button secondary href="https://coastalcares.coastalpay.com/company/CompactFamilyServices/login"
target="_blank"
rel="noopener noreferrer"
className="bg-white text-black">
Create Giving Account
</Button>

<p className="text-center mt-5">Are you an AG Church and want AG giving credit?</p>
<Button secondary href="https://giving.ag.org/donate/compact-family-services?fundid=38231"
target="_blank"
rel="noopener noreferrer"
className="bg-white text-black">
AG Giving Credit
</Button> */}

      </Container>



    
        <Container className="col-lg-10 col-md-12 my-4">
          <ApolloProvider client={client}>
            <Projects />
          </ApolloProvider>

          {/*         
          {isLoading && <div>Loading...</div>}

          {projects &&
            projects.map((project) => (
              <div key={projects.id}>
                {project.completed && (
                  <div className="card px-4 py-3 my-2 bg-light">
                    <h5>
                      <FaClipboardCheck /> <s>{project.project}</s>
                    </h5>
                  </div>
                )}
                {!project.completed && (
                  <div className="card px-4 py-3 my-2">
                    <h5>
                      <FaClipboard /> {project.project}
                    </h5>
                  </div>
                )}
              </div>
            ))} */}
        </Container>
    </>
  );
};

export default Enrollment;
